<script>
  import { get } from 'vuex-pathify'
  import EssentialsNav from '@/components/essentials/EssentialsNav.vue'
  import MarketLockedModals from '@/views/MarketLockedModals'
  // import EssentialsFooter from '@/components/essentials/EssentialsFooter.vue'

  export default {
    components: {
      EssentialsNav,
      MarketLockedModals,
      // EssentialsFooter,
    },
    computed: {
      ...get('essentials', ['loaded']),
      ...get('feature', ['flags']),
      essentialsShopEnabled() {
        return this.flags['essentials-shop-enabled'] ?? false
      },
    },
    async created() {
      if (!this.loaded) {
        this.$store.dispatch('essentials/ensureFresh')
      }
    },
  }
</script>

<template>
  <div class="bg-dawn-lt3">
    <BaseSpinner v-if="!loaded" overlay="fixed" overlay-color="white" class="z-90" />
    <div v-else-if="essentialsShopEnabled">
      <EssentialsNav />
      <RouterView />
      <Portal to="modal">
        <MarketLockedModals type="essentials" />
      </Portal>
      <!-- <EssentialsFooter /> -->
    </div>
  </div>
</template>
