<script>
  import { get, call } from 'vuex-pathify'
  import debounce from 'lodash.debounce'
  import algoliasearch from 'algoliasearch/lite'
  import screen from '@/helpers/screen'
  import EssentialsSortDropdown from '@/components/essentials/EssentialsSortDropdown.vue'
  import EssentialsCategoryBanner from '@/components/essentials/EssentialsCategoryBanner.vue'
  import env from '@/helpers/env'

  /**
   * Nav bar used in Essentials Shop screens.
   */
  export default {
    components: {
      EssentialsSortDropdown,
      EssentialsCategoryBanner,
    },
    async beforeRouteUpdate(to, from, next) {
      this.$store.set('essentials/setSortedProducts', null)
      this.sortValue = 'default'
      next()
    },
    data() {
      return {
        showDesktopSubNav: false,
        showMobileSubNav: false,
        inNavBar: false,
        showSearchInput: false,
        selectedCategory: null,
        disabledSubNav: null,
        sortValue: null,
        mobileSearchOpen: false,
        searchTerms: ['Dish Soap', 'Hand Soap', 'Shampoo', 'Body Wash'],
        placeholder: 'Search',
        innerQuery: '',
        searchClient: null,
        searchIndex: '',
      }
    },
    computed: {
      screen,
      ...get('feature', ['flags']),
      ...get('essentials', [
        'categories',
        'plpIsL2',
        'l1CategorySlug',
        'l1CategoryTitle',
        'currentCategoryTitle',
        'filteredProducts',
        'searchProducts',
        'sortProducts',
        'sortedProducts',
        'sortOptions',
        'query',
      ]),
      selectedSubCategories() {
        return this.selectedCategory?.children
      },
      subNavColumnCount() {
        return Math.ceil(this.selectedSubCategories?.length / 3)
      },
      isEssentialsSearchEnabled() {
        return this.flags['essentials-search'] ?? false
      },
      isAutocompleteSearchEnabled() {
        return (this.flags['essentials-search'] && !this.flags['kill-search-autocomplete']) ?? false
      },
      displayMobileSubNav() {
        return !this.screen.lg && this.$route.name !== 'EssentialsBannedMaterials'
      },
      displayBreadcrumbAsLink() {
        return this.plpIsL2 || this.$route.name === 'EssentialsBannedMaterials'
      },
      isSearch() {
        return this.$route.name === 'EssentialsSearch'
      },
      results() {
        return this.isSearch ? this.searchProducts : this.filteredProducts
      },
      showSortDropdown() {
        return (
          !this.isSearch && this.currentCategoryTitle && this.currentCategoryTitle !== 'Discover'
        )
      },
    },
    watch: {
      '$route.path'() {
        // Close both the mobile nav and the avatar menu when changing route
        this.showDesktopSubNav = false
        this.showMobileSubNav = false
      },
      mobileSearchOpen() {
        if (this.mobileSearchOpen) {
          this.$nextTick(() => {
            this.$refs.searchInputRef.focus()
          })
        }
      },
    },
    created() {
      if (this.isEssentialsSearchEnabled) {
        this.searchClient = algoliasearch(env.get('ALGOLIA_APP_ID'), env.get('ALGOLIA_SEARCH_KEY'))
        this.searchIndex = env.get('ALGOLIA_INDEX') + 'QuerySuggestions'
        this.observeFlag('essentials-search')
      }
    },
    methods: {
      ...call('feature', ['observeFlag']),
      navigateToL1Route(category) {
        return {
          name: 'EssentialsPLP',
          params: {
            categorySlugL1: category.slug,
          },
        }
      },
      navigateToL2Route(category) {
        return {
          name: 'EssentialsPLP',
          params: {
            categorySlugL1: this.selectedCategory.slug,
            categorySlugL2: category.slug,
          },
        }
      },
      /** Selects which debounced method should be called */
      selectDesktopCategory(category) {
        if (this.inNavBar) {
          // Call the slower debounced method
          this.showDesktopSubNavSlow(category)
        } else {
          // Call the quicker debounced method
          this.showDesktopSubNavFast(category)
        }
      },
      /** Sets the sub nav with a 1 second delay */
      showDesktopSubNavSlow: debounce(function (category) {
        this.setSubNav(category)
      }, 1000),
      /** Sets the sub nav with a 500 millisecond delay */
      showDesktopSubNavFast: debounce(function (category) {
        this.setSubNav(category)
      }, 500),
      /** Logic for displaying or hiding a sub nav */
      setSubNav(category) {
        this.inNavBar = true
        this.selectedCategory = category
        if (category.children?.length > 0 && category.id !== this.disabledSubNav?.id) {
          this.showDesktopSubNav = true
        } else {
          this.showDesktopSubNav = false
        }
      },
      setDisabledSubNav(category) {
        this.disabledSubNav = category
        this.showSearchInput = false
      },
      clearDisabledSubNav() {
        this.disabledSubNav = null
      },
      selectMobileCategory(category) {
        this.selectedCategory = category
      },
      hideSubNav: debounce(function () {
        this.showDesktopSubNav = false
        this.selectedCategory = null
        this.inNavBar = false
      }, 500), // 500ms for accessibility
      search(query) {
        this.mobileSearchOpen = false
        this.showSearchInput = false

        this.$navigate({
          name: 'EssentialsSearch',
          params: {
            query,
          },
        })
      },
      onSuggestionSelect(selected) {
        if (selected) {
          this.search(selected.item.query)
        }
      },
      indicesToSuggestions(indices) {
        return indices.map(({ hits }) => ({
          data: hits.map((hit) => {
            return {
              ...hit,
              // this is for Vue AutoSuggest
              name: hit.query,
            }
          }),
        }))
      },
      toggleAutocompleteSearch() {
        this.showSearchInput = !this.showSearchInput
        if (this.$refs.autocompleteInput) {
          if (this.showSearchInput) {
            this.$nextTick(() => {
              this.$refs.autocompleteInput.focus()
            })
          } else {
            this.$nextTick(() => {
              this.$refs.autocompleteInput.blur()
            })
          }
        }
      },
    },
  }
</script>

<template>
  <div>
    <div class="relative bg-black text-dawn border-b-2 border-dawn-dk4">
      <!-- Desktop -->
      <template v-if="screen.lg">
        <div @mouseleave="hideSubNav">
          <BaseContainer max-width="2xl">
            <nav class="flex items-center justify-between h-13">
              <!-- Desktop categories -->
              <ul class="flex items-center text-center">
                <li class="hover:border-dawn border-b">
                  <BaseLink
                    :to="{ name: 'EssentialsHome' }"
                    @mouseover="selectDesktopCategory('Discover')"
                    @focus="selectDesktopCategory('Discover')"
                    @click.native="setDisabledSubNav(null)"
                  >
                    Discover
                  </BaseLink>
                </li>
                <li
                  v-for="(category, categoryIndex) in categories"
                  :key="categoryIndex"
                  :class="`ml-10 hover:border-dawn border-b ${
                    selectedCategory === category ? 'border-dawn border-b' : ''
                  }`"
                >
                  <BaseLink
                    :to="navigateToL1Route(category)"
                    :aria-haspopup="Boolean(category.children.length > 0)"
                    :aria-expanded="showDesktopSubNav"
                    @click.native="setDisabledSubNav(category)"
                    @mouseover="selectDesktopCategory(category)"
                    @mouseout="clearDisabledSubNav"
                    @focus="selectDesktopCategory(category)"
                    @blur="clearDisabledSubNav"
                  >
                    {{ category.title }}
                  </BaseLink>
                </li>
                <li class="ml-10 hover:border-dawn border-b">
                  <BaseLink
                    :to="{ name: 'EssentialsBannedMaterials' }"
                    @mouseover="selectDesktopCategory('Banned Materials')"
                    @focus="selectDesktopCategory('Banned Materials')"
                    @click.native="setDisabledSubNav(null)"
                  >
                    Banned Materials
                  </BaseLink>
                </li>
              </ul>

              <div
                v-if="isEssentialsSearchEnabled && isAutocompleteSearchEnabled"
                class="flex items-center bg-dawn rounded-full || autocomplete"
              >
                <button
                  type="button"
                  :class="`flex justify-center items-center w-7 h-7 text-black rounded-full ${
                    showSearchInput ? 'ml-1 bg-white' : 'bg-dawn'
                  }`"
                  @click="toggleAutocompleteSearch"
                >
                  <BaseIcon type="icon" label="search" :size="3">
                    <IconSearch />
                  </BaseIcon>
                </button>
                <div v-show="showSearchInput" class="text-black">
                  <ais-instant-search :search-client="searchClient" :index-name="searchIndex">
                    <ais-autocomplete>
                      <template v-slot="{ currentRefinement, indices, refine }" class="relative">
                        <input
                          ref="autocompleteInput"
                          type="search"
                          :value="currentRefinement"
                          class="w-full h-9 px-3 font-body font-normal text-base border-0 focus:outline-none || autocomplete-input"
                          placeholder="Search"
                          @input="refine($event.currentTarget.value)"
                          @keyup.enter="search(currentRefinement)"
                        />

                        <div class="absolute z-50 bg-white || autocomplete-menu">
                          <div v-if="currentRefinement && indices.length">
                            <ul v-for="index in indices" :key="index.indexId">
                              <li>
                                <ul v-if="index.hits.length > 0" class="py-3">
                                  <li v-for="hit in index.hits" :key="hit.objectID">
                                    <a
                                      href="#"
                                      class="block px-4 text-sm"
                                      @click.prevent="search(hit.query)"
                                    >
                                      <ais-highlight attribute="query" :hit="hit" />
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                          <div v-else>
                            <ul class="py-3">
                              <li v-for="(term, index) in searchTerms" :key="index">
                                <a
                                  href="#"
                                  class="block px-4 text-sm"
                                  @click.prevent="search(term)"
                                  >{{ term }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>

                        <Portal to="searchOverlay">
                          <transition name="autocomplete-overlay">
                            <div
                              v-if="showSearchInput"
                              class="absolute inset-0 z-40 bg-true-black opacity-80 pointer-events-auto"
                              @click="showSearchInput = false"
                            />
                          </transition>
                        </Portal>
                      </template>
                    </ais-autocomplete>
                  </ais-instant-search>
                </div>
              </div>

              <!-- Desktop Search (placeholder) !TODO: remove outline from tailwind config if end up not using -->
              <div
                v-if="isEssentialsSearchEnabled && !isAutocompleteSearchEnabled"
                class="flex items-center bg-dawn rounded-full"
              >
                <div v-show="showSearchInput" class="px-6 text-black">
                  <input
                    type="text"
                    placeholder="Search"
                    class="w-50 search-input"
                    @keydown.enter.prevent="search($event.target.value)"
                  />
                </div>
                <div
                  class="flex text-black bg-dawn w-9 h-9 rounded-full justify-center items-center cursor-pointer"
                  @click="showSearchInput = !showSearchInput"
                >
                  <BaseIcon type="icon" label="search" :size="6">
                    <IconSearch />
                  </BaseIcon>
                </div>
              </div>
            </nav>
          </BaseContainer>

          <!-- Desktop subcategories -->
          <section
            v-if="showDesktopSubNav"
            class="z-30 absolute inset-x-0 px-6 sm:px-9 bg-black text-dawn pb-4"
          >
            <nav class="max-w-320 mx-auto">
              <BaseDivider color="dawn-dk2" class="mt-1" />
              <ul :style="`column-count: ${subNavColumnCount}`">
                <li
                  v-for="(subCategory, subCategoryIndex) in selectedSubCategories"
                  :key="subCategoryIndex"
                  class="mt-4 w-full inline-block"
                >
                  <BaseLink :to="navigateToL2Route(subCategory)">
                    <span class="hover:border-dawn border-b">{{ subCategory.title }}</span>
                  </BaseLink>
                </li>
              </ul>
            </nav>
          </section>
        </div>

        <EssentialsCategoryBanner v-if="isSearch">
          <template v-slot:cta>
            <div class="underline">Showing results for "{{ query }}"</div>
          </template>
        </EssentialsCategoryBanner>
      </template>

      <!-- Mobile breadcrumbs -->
      <template v-else>
        <div class="px-6 py-3 flex items-center justify-between text-xs">
          <!-- Categories -->
          <div>
            <component
              :is="displayBreadcrumbAsLink ? 'BaseLinkStyled' : 'div'"
              :color="displayBreadcrumbAsLink ? 'white' : undefined"
              :to="displayBreadcrumbAsLink ? { name: 'EssentialsHome' } : undefined"
            >
              Essentials Shop
            </component>
            <!-- L1 is a link if view is L2 (otherwise static text) -->
            <span v-if="displayBreadcrumbAsLink">
              <BaseIcon :size="3" class="inline-block align-middle">
                <IconChevronRightThick />
              </BaseIcon>
              <BaseLinkStyled
                v-if="plpIsL2"
                color="white"
                :to="{
                  name: 'EssentialsPLP',
                  params: { categorySlugL1: l1CategorySlug },
                }"
              >
                {{ l1CategoryTitle }}
              </BaseLinkStyled>
              <span v-else-if="$route.name === 'EssentialsBannedMaterials'" class="text-white">
                Banned Materials
              </span>
            </span>
          </div>
          <!-- Mobile Search (placeholder) -->
          <div
            v-if="isEssentialsSearchEnabled"
            class="bg-dawn rounded-full w-8 h-8 flex items-center justify-center"
          >
            <BaseIcon :size="4" class="text-black" label="search" @click="mobileSearchOpen = true">
              <IconSearch />
            </BaseIcon>
          </div>
        </div>
      </template>
    </div>

    <!-- Desktop Search Breadcrumbs -->
    <div v-if="screen.md && isSearch" class="flex items-center justify-between py-5 md:px-10">
      <div>
        <BaseLinkStyled color="black" :to="{ name: 'EssentialsHome' }"
          >Essentials Shop</BaseLinkStyled
        >
        <span> / </span>
        <span>"{{ query }}" ({{ searchProducts.length }} results) </span>
      </div>
    </div>

    <!-- Mobile subnav -->
    <nav v-if="displayMobileSubNav" class="bg-dawn p-3">
      <div v-if="isSearch">
        <div>Showing results for</div>
        <div class="text-2xl">"{{ query }}"</div>
      </div>
      <BaseLink v-else class="font-heading font-medium text-3xl" @click="showMobileSubNav = true">
        {{ currentCategoryTitle }}
        <BaseIcon :size="4" class="inline-block align-middle ml-1">
          <IconChevronDownThick />
        </BaseIcon>
      </BaseLink>

      <div v-if="results.length" class="text-xs mt-5 flex">
        <span class="font-light">{{ results.length }} result(s)</span>
        <span class="font-light mx-2">&verbar;</span>
        <!-- Sort dropdown -->
        <EssentialsSortDropdown
          v-if="showSortDropdown"
          v-model="sortValue"
          :options="sortOptions"
          @input="sortProducts"
        >
          <span class="font-semibold">
            Sort
            <BaseIcon :size="3" class="inline-block align-middle ml-1">
              <IconChevronDownThick />
            </BaseIcon>
          </span>
        </EssentialsSortDropdown>
      </div>
      <BaseHalfModal full-screen :open="showMobileSubNav" @dismiss="showMobileSubNav = false">
        <ul class="bg-black text-dawn py-9 pl-12 pr-8">
          <li class="mb-3"> Essentials Shop </li>
          <template v-if="!selectedCategory">
            <li class="font-heading text-3xl">
              <BaseLink :to="{ name: 'EssentialsHome' }"> Discover </BaseLink>
            </li>
            <li
              v-for="(category, categoryIndex) in categories"
              :key="categoryIndex"
              class="font-heading text-3xl mt-6"
            >
              <BaseLink
                :aria-haspopup="Boolean(category.children.length > 0)"
                :aria-expanded="showDesktopSubNav"
                class="flex justify-between items-center"
                @click="
                  category.children.length > 0
                    ? selectMobileCategory(category)
                    : $navigate(navigateToL1Route(category))
                "
              >
                {{ category.title }}
                <BaseIcon
                  v-if="category.children.length > 0"
                  :size="6"
                  class="inline-block align-middle"
                >
                  <IconChevronRight />
                </BaseIcon>
              </BaseLink>
            </li>
            <li class="mt-6 font-heading text-3xl">
              <BaseLink :to="{ name: 'EssentialsBannedMaterials' }"> Banned Materials </BaseLink>
            </li>
          </template>
          <template v-else>
            <li class="flex flex-col">
              <div @click="selectedCategory = null">
                <BaseIcon :size="6" class="inline-block absolute left-0 -ml-9 pt-2">
                  <IconChevronLeft />
                </BaseIcon>
                <span class="font-heading text-3xl">{{ selectedCategory.title }}</span>
              </div>
              <BaseLinkStyled color="dawn" :to="navigateToL1Route(selectedCategory)">
                Shop All
              </BaseLinkStyled>
            </li>
            <li
              v-for="(subCategory, subCategoryIndex) in selectedSubCategories"
              :key="subCategoryIndex"
              class="mt-11 text-lg"
            >
              <BaseLink :to="navigateToL2Route(subCategory)">
                {{ subCategory.title }}
              </BaseLink>
            </li>
          </template>
        </ul>
      </BaseHalfModal>
    </nav>

    <!-- Mobile search -->
    <transition name="mobile-search">
      <div
        v-show="mobileSearchOpen"
        class="z-90 h-full bg-white fixed overflow-y-auto inset-0 p-6 lg:hidden"
      >
        <ais-instant-search
          v-if="isAutocompleteSearchEnabled"
          :search-client="searchClient"
          :index-name="searchIndex"
        >
          <ais-autocomplete>
            <template v-slot="{ currentRefinement, indices, refine }" class="relative">
              <div>
                <span class="text-xs ml-4">Essentials Shop</span>
                <div class="flex items-center">
                  <!-- Search bar -->
                  <div class="w-full my-6 mr-6">
                    <input
                      ref="searchInputRef"
                      type="text"
                      :value="currentRefinement"
                      class="border-black border-1 w-full rounded-full h-12 pl-4 search-input"
                      :placeholder="placeholder"
                      @input="refine($event.currentTarget.value)"
                      @keyup.enter="search(currentRefinement)"
                    />
                    <!-- Clear input text ('X' icon) -->
                    <button
                      v-if="currentRefinement"
                      class="absolute right-0 top-0 mt-2 mr-10"
                      @click="refine('')"
                    >
                      <BaseIconInput :size="3" color="black" label="search">
                        <IconXThick />
                      </BaseIconInput>
                    </button>
                    <!-- Search button ('search' icon) -->
                    <div
                      class="absolute right-0 top-0 mt-2 mr-2 bg-dawn rounded-full w-8 h-8 flex items-center justify-center"
                    >
                      <BaseIconInput
                        :size="4"
                        color="black"
                        label="search"
                        @click="search(innerQuery)"
                      >
                        <IconSearch />
                      </BaseIconInput>
                    </div>
                  </div>

                  <!-- Dismiss icon to close mobile search -->
                  <BaseIcon
                    :size="6"
                    label="dismiss"
                    class="dismiss-icon"
                    @click="mobileSearchOpen = false"
                  >
                    <IconXThick />
                  </BaseIcon>
                </div>
                <!-- Hard coded suggested search terms -->
                <section v-if="currentRefinement" class="ml-4 search-terms">
                  <ul v-for="index in indices" :key="index.indexId" class="text-xl mt-4">
                    <li>
                      <ul>
                        <li v-for="hit in index.hits" :key="hit.objectID">
                          <a href="#" class="block" @click.prevent="search(hit.query)">
                            <ais-highlight attribute="query" :hit="hit" />
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>

                <section v-else class="ml-4 search-terms">
                  <h2>Popular Search Terms</h2>
                  <ul>
                    <li
                      v-for="(term, termIndex) in searchTerms"
                      :key="termIndex"
                      class="text-xl mt-4"
                    >
                      <BaseLink @click="search(term)">
                        {{ term }}
                      </BaseLink>
                    </li>
                  </ul>
                </section>
              </div>
            </template>
          </ais-autocomplete>
        </ais-instant-search>

        <div v-if="!isAutocompleteSearchEnabled">
          <span class="text-xs ml-4">Essentials Shop</span>
          <div class="flex items-center">
            <div class="w-full my-6 mr-6">
              <input
                ref="searchInputRef"
                v-model="innerQuery"
                :placeholder="placeholder"
                class="border-black border-1 w-full rounded-full h-12 pl-4 search-input"
                @keydown.enter.prevent="search(innerQuery)"
              />

              <button
                v-if="innerQuery"
                class="absolute right-0 top-0 mt-2 mr-10"
                @click="innerQuery = ''"
              >
                <BaseIconInput :size="3" color="black" label="search">
                  <IconXThick />
                </BaseIconInput>
              </button>
              <div
                class="absolute right-0 top-0 mt-2 mr-2 bg-dawn rounded-full w-8 h-8 flex items-center justify-center"
              >
                <BaseIconInput :size="4" color="black" label="search" @click="search(innerQuery)">
                  <IconSearch />
                </BaseIconInput>
              </div>
            </div>

            <BaseIcon
              :size="6"
              label="dismiss"
              class="dismiss-icon"
              @click="mobileSearchOpen = false"
            >
              <IconXThick />
            </BaseIcon>
          </div>
          <section class="ml-4 search-terms">
            <h2>Popular Search Terms</h2>
            <ul>
              <li v-for="(term, termIndex) in searchTerms" :key="termIndex" class="text-xl mt-4">
                <BaseLink @click="search(term)">
                  {{ term }}
                </BaseLink>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
  >>> .ais-Highlight-highlighted {
    @apply bg-black text-dawn;
  }

  .autocomplete-input {
    min-width: 254px;
    box-shadow: none !important;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 13px;
      height: 13px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyMCAyMCI+PHBvbHlnb24gcG9pbnRzPSIxMC43IDEwIDE4LjM1IDE3LjY1IDE3LjY1IDE4LjM1IDEwIDEwLjcgMi4zNSAxOC4zNSAxLjY1IDE3LjY1IDkuMyAxMCAxLjY1IDIuMzUgMi4zNSAxLjY1IDEwIDkuMyAxNy42NSAxLjY1IDE4LjM1IDIuMzUgMTAuNyAxMCIvPjwvc3ZnPg==');
    }
  }

  .autocomplete-menu {
    top: 44px;
    right: 32px;
    left: 0;
  }

  .autocomplete-overlay {
    &-enter-active,
    &-leave-active {
      @apply transition duration-200;
    }

    &-enter,
    &-leave-to {
      @apply opacity-0;
    }
  }

  .mobile-search {
    &-leave-active,
    &-enter-active {
      @apply transition duration-400;
    }

    &-leave-to,
    &-enter {
      @apply opacity-0;
    }
  }

  .search-input {
    animation: expand 0.7s 1;
  }

  @keyframes expand {
    0% {
      transform: scaleX(0);
      transform-origin: right;
    }

    100% {
      transform: scaleX(1);
      transform-origin: right;
    }
  }

  .dismiss-icon {
    animation: pulse 1.4s 1;
  }
  @keyframes pulse {
    0% {
      transform: scale(0);
    }

    40% {
      transform: scale(0.5);
    }

    70% {
      transform: scale(1.3);
    }

    100% {
      transform: scale(1);
    }
  }

  .search-terms {
    animation: fade 1.3s 1 ease-in;
  }
  @keyframes fade {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
</style>
